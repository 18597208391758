// css
import "../styles/main.scss";

// vendor
import "@babel/polyfill";
import ready from "document-ready";
import Webfontloader from "webfontloader";
import "lazysizes/plugins/object-fit/ls.object-fit";
import "lazysizes";

// utils
import "./utils/foreach-polyfill.js";
import ModuleRouter from "./utils/module-router";
import detectTouch from "./utils/detect-touch";
import ScrollInView from "./utils/scroll-inview";

// partials
import Header from "./partials/header";
import Nav from "./partials/nav";

// modular
import Announcement from "./modular/announcement";
import FeedbackForm from "./modular/feedback-form";
import TextAccordion from "./modular/text-accordion";
import TextAndImageRows from "./modular/text-and-image-rows";

// for ie11 support (optional)
const ES6Promise = require("es6-promise");
ES6Promise.polyfill();

ready(() => {
  detectTouch.init();

  Webfontloader.load({
    custom: {
      families: [
        "apercu-mono-regular-pro",
        "apercu-light-pro",
        "Apercu-Regular",
        "apercu-bold-pro",
      ],
    },
    active: () => {
      init();
    },
    inactive: () => {
      init();
    },
  });
});

function init() {
  // Router Modules
  const modularClasses = {
    Announcement: Announcement,
    FeedbackForm: FeedbackForm,
    TextAccordion: TextAccordion,
    TextAndImageRows: TextAndImageRows,
  };

  const moduleRouter = new ModuleRouter(modularClasses); // eslint-disable no-unused-vars
  moduleRouter.fireEvent("init");

  const scrollInView = new ScrollInView();
  scrollInView.init();

  const nav = new Nav(".js-nav");
  nav.init();

  const header = new Header(".js-header");
  header.init();

  document.body.classList.remove("is-loading");
}
