import Dom from "../utils/dom";

class Announcement {
  constructor(el) {
    this.dom = new Dom(el, ["close"]);

    this.dom.close.forEach((button) => {
      button.onclick = (event) => {
        event.preventDefault();
        el.classList.add("isHidden");
      };
    });
  }
}

export default Announcement;
