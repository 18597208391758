import Dom from '../utils/dom'
import Carousel from '../partials/carousel'

class TextAndImageRows {
  constructor (el) {
    this.dom = new Dom(el, [
      'slides',
      'slide'
    ])

    if (this.dom.slide.length > 1) {
      this.carousel = new Carousel(this.dom.slides[0])
    }
  }
}

export default TextAndImageRows
