import Dom from '../utils/dom'
import anime from 'animejs'

class TextAccordion {
  constructor (el) {
    this.dom = new Dom(el, [
      'item',
      'itemLink',
      'itemContent'
    ])

    this.dom.item.forEach((el, index) => {
      if (el.dataset.open === 'true') {
        const content = el.querySelector('.js-itemContent')
        const contentHeight = content.clientHeight + 'px'
        el.style.paddingBottom = contentHeight
      }

      el.querySelector('.js-itemLink').onclick = (event) => {
        event.preventDefault()

        // get primary nav item
        const item = event.currentTarget.parentElement
        const content = item.querySelector('.js-itemContent')

        if (!this.animating) {
          if (item.dataset.open === 'true') {
            item.setAttribute('data-open', 'false')
            this.animating = true

            anime({
              targets: item,
              duration: 400,
              paddingBottom: 0,
              easing: 'easeOutCirc',
              complete: () => {
                this.animating = false
              }
            })
          } else {
            const contentHeight = content.clientHeight + 'px'
            this.animating = true
            item.setAttribute('data-open', 'true')

            anime({
              targets: item,
              duration: 400,
              paddingBottom: contentHeight,
              easing: 'easeOutCirc',
              complete: () => {
                this.animating = false
              }
            })
          }
        }
      }
    })

    this.dom.root.setAttribute('data-ready', 'true')
  }
}

export default TextAccordion
