import Dom from '../utils/dom'
import * as Choices from 'choices.js'

class FeedbackForm {
  constructor (el) {
    this.dom = new Dom(el, [
      'multiSelect'
    ])

    this.choices = new Choices('.js-selectBox', {
      searchEnabled: false,
      itemSelectText: '',
      shouldSort: false,
      classNames: {
        containerOuter: 'feedbackForm__select',
        containerInner: 'feedbackForm__select__inner',
        input: 'feedbackForm__select__input',
        inputCloned: 'feedbackForm__select--cloned',
        list: 'feedbackForm__select__list',
        listItems: 'feedbackForm__select__list--multiple',
        listSingle: 'feedbackForm__select__list--single',
        listDropdown: 'feedbackForm__select__list--dropdown',
        item: 'feedbackForm__select__item',
        itemSelectable: 'feedbackForm__select__item--selectable',
        itemDisabled: 'feedbackForm__select__item--disabled',
        itemChoice: 'feedbackForm__select__item--choice',
        placeholder: 'feedbackForm__select__placeholder',
        group: 'feedbackForm__select__group',
        groupHeading: 'feedbackForm__select__heading',
        button: 'feedbackForm__select__button',
      }
    })
  }
}

export default FeedbackForm
