import Dom from '../utils/dom'

class Nav {
  constructor (selector) {
    const el = document.querySelector(selector)
    document.documentElement.setAttribute('data-nav-open', 'false')
    this.dom = new Dom(el, [
      'btnMenu',
      'navItem'
    ])
  }

  init () {
    // menu toggle
    if (this.dom.exists('btnMenu')) {
      this.dom.btnMenu[0].onclick = (event) => {
        event.preventDefault()
        this.toggle()
      }
    }
  }

  toggle () {
    if (document.documentElement.dataset.navOpen === 'true') {
      document.documentElement.setAttribute('data-nav-open', 'false')
    } else {
      document.documentElement.setAttribute('data-nav-open', 'true')
    }
  }
}

export default Nav
