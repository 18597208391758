import * as Headroom from 'headroom.js'
import Dom from '../utils/dom'

class Header {
  constructor (selector) {
    const el = document.querySelector(selector)
    this.dom = new Dom(el, [])
  }

  init () {
    if (this.dom.root) {
      const headroom = new Headroom(this.dom.root, { offset: 45 })
      headroom.init()
    }
  }
}

export default Header
