import * as basicScroll from 'basicscroll'

class ScrollInView {
  constructor () {
    this.els = Array.from(document.querySelectorAll('[data-scrolled-to]'))
  }

  init () {
    const windowHeight = document.documentElement.clientHeight

    // set delay for performance
    setTimeout( () => {
      this.els.forEach((el, index) => {
        if (el.getBoundingClientRect().top < windowHeight) {
          el.setAttribute('data-scrolled-to', 'true')
        } else {
          el.setAttribute('data-scrolled-to', 'false')
        }

        const instance = basicScroll.create({
          elem: el,
          from: 'top-middle',
          to: 'bottom-middle',
          inside: (instance, percentage, props) => {
            if (!instance.scrolledTo) {
              instance.scrolledTo = true
              instance.getData().elem.setAttribute('data-scrolled-to', 'true')
            }
          }
          /* outside: (instance, percentage, props) => {
            if ( instance.scrolledTo ) {
              instance.scrolledTo = false
              instance.getData().elem.setAttribute('data-scrolled-to', 'false')
            }
          } */
        }).start()
      })
    }, 100)
  }
}

export default ScrollInView
